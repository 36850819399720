import { render, staticRenderFns } from "./TargetDB.vue?vue&type=template&id=673b6600&scoped=true"
import script from "./TargetDB.vue?vue&type=script&lang=ts"
export * from "./TargetDB.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "673b6600",
  null
  
)

export default component.exports