<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>Target DB</h2>
    </div>
    <div class="ibox-content p-md">

      <form role="form" @submit="onFormSubmit" name="targetDBForm">
        <div class="form-group row">
          <div class="col">
            Current target DB: {{currentTargetDB}}
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <ListDBs 
                v-bind:value.sync="form.targetDB" 
                id="targetDB" 
                label="Target DB" 
                placeholder="Select a target DB" 
                labelAsColumn 
              />
          </div>
        </div>
        <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">Update Target DB</button>
        <button class="btn btn-primary ladda-button refresh" data-style="zoom-in" type="button" @click="refreshListModules()">Refresh list modules</button>
      </form>

    </div>
  </div>
        
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '../../../../store/application';
import ListDBs from '../input/ListDBs.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { updateModulesForLoggedInUser } from '@root/src/client/utils/application';

export default defineComponent({
    props: {
        
    },
    components: {
        ListDBs
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const $store = useStore();

        const form:any = reactive({
          targetDB:""
        });

        var laddaSubmit:Ladda.LaddaButton|null = null;

        onMounted(() => {
          var button:HTMLButtonElement|null = document.querySelector( 'form[name=targetDBForm] button.refresh' );
          laddaSubmit = Ladda.create(button!);
        })

        const currentTargetDB = computed(() => {
          return $store.getters['application/'+applicationTypes.getters.GET_ADMIN_TARGET_DB];
        })

        const onFormSubmit = (evt:Event) => {
          evt.preventDefault();

          // We update application store
          $store.commit('application/'+applicationTypes.mutations.SET_ADMIN_TARGET_DB, form.targetDB)
        }

        const refreshListModules = () => {
          // We refresh the list of modules for current user based on the new target DB
          laddaSubmit!.start();

          // We check if we have a target DB for the content
          var input:any = {};
          var targetDB = $store.getters['application/'+applicationTypes.getters.GET_ADMIN_TARGET_DB];
          if(targetDB) {
            input.targetDB = targetDB;
          }

          var options:api.ApiVueOptions =  {
            app: app
          }
          api.postAPI('/api/admin/user/modules', input, options).then((response:any) => {
            laddaSubmit!.stop();
            if(response.modules) {  
              updateModulesForLoggedInUser($store, response.modules);
            }
          });
        }

        return {
          currentTargetDB,
          onFormSubmit,
          refreshListModules,
          form
        }
  }
})
</script>